import axios from 'axios';



//https://bingenfiesta.com
const api = axios.create({
    baseURL: 'https://tincywincy.in/api/booking',
    withCredentials: true,
    headers: {
        "Content-Type": "application/json",
        Accept: "application/json"
    }
})

export const getCityData = async (date) => api.get(`/getcity/${date}`);
export const getPlanData = async (date) => api.get(`/getplan/${date}`);
export const getSlotsData = async (date, plan) => api.get(`/getslot/${date}/${plan}`);
export const getSelectedPlan = async (plan) => api.get(`/getselectedplan/${plan}`)
export const getDecorations = async () => api.get('/getdecorations');
export const getCakes = async () => api.get('/getcakes');
export const getAddons = async () => api.get('/getaddons')
export const getGallery = async () => api.get('/gallery')

export const createCheckout = async (data) => api.post('/checkout/create', data);
export const verifyCheckOut = async (tid, data) => api.post(`/checkout/verify/${tid}`, data);
export const getBookingById = async (id) => api.get(`/bbooking/get/${id}`);

export const sentOtpReq = async (data) => api.post('/auth/otp', data);
export const verifyOtpReq = async (data) => api.post('/auth/verify', data);
export const autoLogin = async () => api.get('/auth/auto');
export const logout = async () => api.get('/auth/logout');

export const getBookingByUser = async () => api.get('/bookings/get');
export const cancelBooking = async (id) => api.get(`/cancel/${id}`);
export const getCityByID = async (id) => api.get(`/city/${id}`);
export const createBookingRequest = async (data) => api.post('/bookingrequest/create', data);
export const getAppSeting = async () => api.get('/app/seting');
export const applyCoupan = async (data) => api.post('/coupan/apply', data);
export const getCoupans = async () => api.get('/coupan/get');