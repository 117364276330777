import { useState, useEffect, useContext } from "react";
import { getCityData, getPlanData } from "../http";
import { AuthContext } from "../context/AuthContext";
import dayjs from "dayjs";


const useBooking = () => {

    const auth = useContext(AuthContext)

    const [date, setDate] = useState(dayjs().format('YYYY-MM-DD'));
    const [plan, setPlan] = useState('');
    const [slot, setSlot] = useState(null);
    const [numberOfPerson, setNumberOfPerson] = useState(null);
    const [decoration, setDecoration] = useState({price: 0});
    const [cake, setCake] = useState({price: 0, eggless_price: 0});
    const [addon, setAddon] = useState([]);
    const [name, setName] = useState(auth.name);
    const [mobile, setMobile] = useState(auth.mobile);
    const [email, setEmail] = useState(auth.email);
    const [amount, setAmount] = useState();
    const [eggless, setEggless] = useState(false);
    const [theaterAmount, setTheaterAmount] = useState(0);
    const [nickname_decoration, setNicknameDecoration] = useState('');
    const [nickname_cake, setNickNameCake] = useState('');
    const [age, setAge] = useState();

    const [food, setFood] = useState(false)

    useEffect(() => {
        let amt = theaterAmount;
        let camt = eggless ? cake.eggless_price : cake.price;
        let damt = decoration.price;
        let aamt = addon.reduce((sum, current) => sum + current.price, 0)

        let total = amt + camt + damt + aamt;

        setAmount(total);

    }, [decoration, cake, addon, eggless])


    //data from server
   
    const [planData, setPlanData] = useState({plans : [], slots : {}});
    const [slotsData, setSlotsData] = useState({slots: []});


    // useEffect(() => {
    //     const getPlan = async () => {
    //         try {
    //             const {data} = await getPlanData(date, city);
    //             setPlanData(data)
    //         } catch (error) {
    //             console.log(error)  
    //         }
    //     }
    //     getPlan()
    // }, [date, city])



    return {
        planData,
        slotsData,
        date,
        setDate,
        setPlan,
        numberOfPerson,
        setNumberOfPerson,
        slot, 
        setSlot,
        plan,
        amount,
        setAmount,
        decoration,
        setDecoration,
        cake,
        setCake,
        addon,
        setAddon,
        name,
        setName,
        email,
        setEmail,
        mobile,
        setMobile,
        eggless,
        setEggless,
        theaterAmount,
        setTheaterAmount,
        food,
        setFood,
        nickname_decoration,
        setNicknameDecoration,
        nickname_cake,
        setNickNameCake,
        age,
        setAge
    }
}

export default useBooking;