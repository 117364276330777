import City from "./bookingsteps/City";
import Plans from "./bookingsteps/Plans";
import Slot from './bookingsteps/Slot';
import Decorations from "./bookingsteps/Decoration";
import Cakes from "./bookingsteps/Cake";
import Review from './bookingsteps/Review';
import Addon from './bookingsteps/Addon'

import { useState } from "react";
import useBooking from "../hooks/useBooking";
import Food from "./bookingsteps/Food";

const BookNow = () =>  {

    const [step, setStep] = useState(1);

    const {
        date,
        setDate,
        planData,
        setPlan,
        slot, 
        setSlot,
        numberOfPerson,
        setNumberOfPerson,
        plan,
        amount,
        setAmount,
        decoration,
        setDecoration,
        cake,
        setCake,
        addon,
        setAddon,
        name,
        setName,
        email,
        setEmail,
        mobile,
        setMobile,
        eggless,
        setEggless,
        theaterAmount,
        setTheaterAmount,
        food,
        setFood,
        nickname_decoration,
        setNicknameDecoration,
        nickname_cake,
        setNickNameCake,
        age,
        setAge

    } = useBooking();

    const stepMap = {
        1: <Plans theaterAmount={theaterAmount} setTheaterAmount={setTheaterAmount} setStep={setStep} step={step} date={date} setDate={setDate} setPlan={setPlan} planData={planData}  />,
        2: <Slot  theaterAmount={theaterAmount} setTheaterAmount={setTheaterAmount}  slot={slot} setStep={setStep} step={step} amount={amount} setAmount={setAmount}  date={date} setDate={setDate} plan={plan} setNumberOfPerson={setNumberOfPerson} setSlot={setSlot} numberOfPerson={numberOfPerson} />,
        3: <Decorations nickname_decoration={nickname_decoration} setNicknameDecoration={setNicknameDecoration} amount={amount} setStep={setStep} step={step} date={date} setDate={setDate} decoration={decoration} setDecoration={setDecoration} />,
        4: <Cakes nickname_cake={nickname_cake} setNickNameCake={setNickNameCake}   amount={amount} eggless={eggless} setEggless={setEggless} setStep={setStep} step={step} cake={cake} setCake={setCake} />,
        5: <Addon age={age} setAge={setAge}  amount={amount} setStep={setStep} step={step} addon={addon} setAddon={setAddon}  />,
        6: <Food step={step} setStep={setStep} amount={amount} food={food} setFood={setFood} />,
        7: <Review age={age} setAge={setAge} nickname_decoration={nickname_decoration} setNicknameDecoration={setNicknameDecoration} nickname_cake={nickname_cake} setNickNameCake={setNickNameCake} food={food}  eggless={eggless} name={name} setName={setName} email={email} setEmail={setEmail} mobile={mobile} setMobile={setMobile} setStep={setStep} step={step} decoration={decoration} cake={cake} addon={addon} numberOfPerson={numberOfPerson} date={date} slot={slot} amount={amount} plan={plan} />
    }



    return (
        stepMap[step]
    )
}

export default BookNow;