



import {Checkbox} from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import { useRef, useEffect } from 'react';

const TermsModel = ({setShowModel, onNext, agreed_with_term, setAgrred}) => {

    const handleAgreed = () => {
        if(!agreed_with_term){
            toast.error("You must agree with our terms & conditions", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            })
            return
        }
        onNext();
    }

    const outerRef = useRef(null);
    const innerRef = useRef(null);




    useEffect(() => {
        const handleClickOutside = (event) => {
          if (innerRef.current && !innerRef.current.contains(event.target)) {
            setShowModel(false)
          }
        };
    
        // Attach the event listener to the document
        outerRef.current.addEventListener('click', handleClickOutside);
      }, []);
    

    
    return (
        <div className="login-overlay" ref={outerRef}>
            <div className="login-content term-modal" ref={innerRef}>
            <div style={{width: '100%', height: '400px', overflowY: 'scroll'}}>
               <h1 className="accent-heading">Terms & Conditions</h1>
               <p data-list='1' className="text term-list">
                 We do not provide any Movies / OTT accounts. we will do the setup using your OTT acount / downloaded content.
                </p>
                <p data-list='2' className="text term-list">
                  Smoking/Drinking is not allowed inside the theater.
                </p>
                <p data-list='3' className="text term-list">
                 Any Damage caused to theater, including decorative material like baloon, lights etc will have to be reimbursed.
                </p>
                <p data-list='4' className="text term-list">
                 Guests are requested to maintain CLEANINESS inside the theater.
                </p>
                <p data-list='5' className="text term-list">
                 Party Poppers/Snow Sprayes/Cold Fire, and any other similar items are strictily prohibited inside the theater.
                </p>
                <p data-list='6' className="text term-list">
                 Carrying ADHAR CARD is mendatory. it will be scanned during entry.
                </p>
                <p data-list='7' className="text term-list">
                 Coupled under 18 year of age are not allowe to book the theater.
                </p>
                <p data-list='8' className="text term-list">
                 Pets are strictily not allowed inside the theater.
                </p>
                <p data-list='9' className="text term-list">
                 We collect an advance amount of RS. 750 plus convenience fee to book the slot.
                </p>

                <h1 className="accent-heading">Refund Policy</h1>
                <p className="text">
                    <strong>
                        Advance amount is fully refundable if slot is cancelled at least 72 hrs before the slot time.
                        if your slot is less than 72 hrs away from time of payment then advance is non-refundable.
                    </strong>
                </p>
            </div>
            <div style={{paddingTop:'15px'}}>
            <div style={{display: 'flex', alignItems: 'center', marginBottom:'10px'}}>
            <Checkbox checked={agreed_with_term} onChange={(e) => setAgrred(e.target.checked)} name="" />
            <p className='text' style={{marginLeft: '6px'}}>I agree with above terms & conditions</p>
            </div>
            <button onClick={handleAgreed} type="button" class="btn btn-gradient btn-border">Pay and book</button>
            </div>
            </div>
        </div>
    )
}

export default TermsModel;