
import {Checkbox} from '@mui/material';

const Food = ({amount, step, setStep, food, setFood}) => {

    const onNext = () => {

       
        setStep(step => step + 1)
     }
    return (
        <div>
            <div className='hero-inn'>
            <section id="addon-hero">
                <div className="my-container">
                   <div className="row" style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                 
                   <h1 className="hero-heading">Food</h1>
                   </div>
                </div>
            </section>
            </div>
            <section style={{padding:'60px 0'}} className='bg-light'>
                <div className="my-container">
                   <div className="price-row">
                      <h1 className="heading-primary" style={{color:'#fff'}}>Select Cake</h1>
            <p className="accent-heading" style={{marginBottom: '0'}}>Total: RS. {amount}</p>
            </div>

            <div style={{paddingTop: '60px'}}>
            <p className="text">
                        Expriences our in-house culinary Delights, where quality meet affordability. We take pride in offering a wide range of 
                        dilicious options prepared with the freshest ingradient. Enjoy the convenience and exquisite flavors, all at an affordable price.
                    </p>

                    <p className="text" style={{color: '#f95e5e'}}>Please note, to maintain our high standerds food quality and safety, outside food is tricktely prohibited.</p>
                    <div style={{display: 'flex', alignItems: 'center', marginBottom:'10px'}}>
            <Checkbox name="" checked={food} onChange={(e) => setFood(e.target.checked)} />
            <p className='text' style={{marginLeft: '6px'}}>Opt-in for in-house kitchen food.</p>
            </div>

            <div className="row btn-row" style={{justifyContent: 'space-between'}}>
            <div className="btn-gr-border">
                              <button type="button" class="btn" onClick={() => setStep(step => step - 1)}>Go Back</button>
                            </div>
                             
                            <button type="button" class="btn btn-gradient btn-border" onClick={onNext}>Next</button>
            </div>
            </div>



                </div>

            </section>
        </div>
    )
}

export default Food;