import { useState, useRef, useEffect } from "react";
import { NavLink } from "react-router-dom";

import { AuthContext } from "../context/AuthContext";
import { AuthDispatchContext } from "../context/AuthContext";
import { useContext } from "react";
import { logout, getCoupans } from "../http";
import { toast } from 'react-toastify';
import Marquee from "react-fast-marquee";



const Header = () => {

  const [collapsed, setCollapsed] = useState(true);


  const [coupans, setCoupans] = useState([])

  const auth = useContext(AuthContext);
  const dispatch = useContext(AuthDispatchContext)
  console.log('auth', auth)

 

  useEffect(() => {
    const fetchCoupan = async () => {
      try {
        const {data} = await getCoupans();
        setCoupans(data.coupans)
      } catch (error) {
        console.log(error)
      }
    }
    fetchCoupan();
  }, [])

  const onLogout = async (id) => {

  
     try {
         const {data} = await toast.promise(
             logout(id),
             {
               pending: 'Logging out..',
               success: 'Logged Out',
             }
         )
         dispatch({type: 'logout'})
         window.location.reload()
         
     } catch (error) {
         console.log(error)
         toast.error(error.response.data.message, {
             position: "top-right",
             autoClose: 5000,
             hideProgressBar: false,
             closeOnClick: true,
             pauseOnHover: true,
             draggable: true,
             progress: undefined,
             theme: "light",
         })
         return
     }
    
 }

  return (
    <>
    <section id="header">
      {/* <div id="header-top" style={{display: stick ? 'none' : 'block'}}>
        <div className="my-container" style={{display: 'flex', justifyContent: 'space-between'}}>
        <a href='https://maps.app.goo.gl/hBy59VsmWfrZWkYu8' target="_blank" className="footer-link footer-contact" style={{color: '#fff'}}>
                                        <i class="fa-solid fa-location-dot"></i> 
                                        <span>Hyderabad, India</span>
                                    </a>
                                    <a href='tel:+919502190061' className="footer-link footer-contact" style={{color: '#fff'}}>
                                        <i class="fa-solid fa-phone"></i> 
                                        <span>+91 95021 90061</span>
                                    </a>
        </div>
      </div> */}
      <div className="nav-box" style={{padding:'15px 50px'}}>
        <nav className="row nav-b-x-inner">
          <NavLink to={'/'}>
             <img alt="logo" src="/assets/img/logot.png" style={{width: '100px'}} />
             {/* <h1 className="heading-primary" style={{color: '#fca8c3'}}>Logo</h1> */}
          </NavLink>
          <div className={`nav-mobie-overlay ${collapsed ? 'collapsed' : ''}`}>
            <div className="nav-mobile-sidebar">
              <div className="mobile-nav-header">
              <NavLink to={'/'}>
                <img alt="logo" src="/assets/img/logot.png" style={{width: '100px'}} />
                {/* <h1 className="heading-primary" style={{color: '#fca8c3'}}>Logo</h1> */}
              </NavLink>
              <div className="btn-gr-border">
              <button className="btn" style={{width: '50px', height: '50px', padding: '0',}} onClick={() => setCollapsed(true)}><i className="fa fa-times"></i></button>
              </div>
              </div>
              <div className="mobile-nav-body">
              <div className="navbar-nav mx-auto nav-mobile">
            <ul className="navbar-nav">
              <li className="nav-item">
                <NavLink to={'/'} onClick={() => setCollapsed(true)}><a className={`nav-link`} >Home</a></NavLink>
              </li>
              <li className="nav-item">
                <NavLink to={'/about-us'} onClick={() => setCollapsed(true)}><a href="#" className={`nav-link`}>About</a></NavLink>
              </li>
              <li className="nav-item">
                <NavLink to={'/gallery'} onClick={() => setCollapsed(true)}><a className={`nav-link`}>Gallery</a></NavLink>
              </li>
              <li className="nav-item">
                <NavLink to={'/refund-policy'} onClick={() => setCollapsed(true)}><a className={`nav-link`}>Refund</a></NavLink>
              </li>
              <li className="nav-item">
                <NavLink to={'/addons'} onClick={() => setCollapsed(true)}><a className={`nav-link`}>Add-Ons</a></NavLink>
              </li>
              <li className="nav-item">
                <NavLink to={'/my-booking'} onClick={() => setCollapsed(true)}><a className={`nav-link`}>My Booking</a></NavLink>
              </li>
              <li style={{marginTop: '20px'}}>
                <NavLink to={'/booknow'} onClick={() => setCollapsed(true)}><button className="btn btn-gradient">Book Now</button></NavLink>
              </li>
              {
                auth._id ? (
                  <li style={{marginTop: '20px'}}>
                <span style={{cursor: 'pointer'}} onClick={onLogout}><p className={`nav-link`}>Logout</p></span>
              </li>
                ) : null
              }
            </ul>
           
          </div>
              </div>
            </div>
          </div>

          <div style={{display: 'flex', alignItems: 'center'}}>
          <div className="navbar-nav mx-auto nav-desktop">
            <ul className="navbar-nav">
              <li className="nav-item">
                <NavLink to={'/'}><a className={`nav-link`} >Home</a></NavLink>
              </li>
              <li className="nav-item">
                <NavLink to={'/about-us'}><a className={`nav-link`}>About</a></NavLink>
              </li>
              <li className="nav-item">
                <NavLink to={'/gallery'}><a className={`nav-link`}>Gallery</a></NavLink>
              </li>
              <li className="nav-item">
                <NavLink to={'/my-booking'}><a className={`nav-link`}>My Booking</a></NavLink>
              </li>
              <li className="nav-item">
                <NavLink to={'/addons'}><a className={`nav-link`}>Add-Ons</a></NavLink>
              </li>
              <li className="nav-item">
                <NavLink to={'/refund-policy'}><a className={`nav-link`}>Refund</a></NavLink>
              </li>
              {
                auth._id ? (
                  <li className="nav-item">
                <span style={{cursor: 'pointer'}} onClick={onLogout}><p className={`nav-link`}>Logout</p></span>
              </li>
                ) : null
              }
            </ul>
          </div>
 
          <div style={{display: 'flex'}}>
          <div className="toggle">
          <div className="btn-gr-border" style={{borderRadius: '7px'}}>
            <button className="btn " style={{width: '50px', height: '50px', padding: '0',}} onClick={() => setCollapsed(false)}><i className="fa fa-bars"></i></button>
            </div>
          </div>

          <NavLink to={'/booknow'}><button
            className="btn btn-gradient2 header-btn"
            type="submit"
          >
            Book Now
          </button></NavLink>
          </div>
          </div>

        </nav>
      </div>
    
    {coupans.length > 0 && (<div id="offer-strip">
      <div className="" style={{paddingLeft: '50px', paddingRight: '50px'}}>
        <Marquee autoFill={true} pauseOnHover={true}>
          {
            coupans.map((cp) => <p style={{margin: '0 20px'}}>{cp.text}</p>)
          }
        </Marquee>
      </div>
    </div>)}
    </section>
    </>
  )
}

export default Header;