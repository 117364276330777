import {Outlet} from 'react-router-dom';

import Header from './components/Header';
import Footer from './components/Footer';
import { useRef, useEffect, useState } from 'react';

const Layout = () => {


  const [headerHeight, setHeaderHeiight] = useState(65);




    return (
       <div id="page">
         <Header />
         { <Outlet /> }
         <Footer />
       </div>
    )
}


export default Layout;