

const Refund = () => {
    return (
        <div>
            <div className="hero-inn">
            <section id="addon-hero">
                <div className="my-container">
                   <div className="row" style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                 
                   <h1 className="hero-heading">Refund Policy</h1>
                   </div>
                </div>
            </section>
            </div>
  
           <section className="bg-light">
            <div className="my-container" style={{padding: '60px 0'}}>
                <p data-list='1' className="text term-list">
                Advance amount is fully refundable if slot is cancelled at least 72 hrs before the slot time.
                if your slot is less than 72 hrs away from time of payment then advance is non-refundable.
                </p>

           
            </div>
           </section>

       </div>
    )
}

export default Refund;