import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

import useBooking from '../../hooks/useBooking';
import dayjs from 'dayjs';
import { useState, useEffect } from 'react';
import { getPlanData } from '../../http';
import BookingRequest from '../BookingRequest/BookingRequest';


const Plans = ({date, setDate, step, setStep, setPlan, theaterAmount, setTheaterAmount}) => {

    useEffect(() => {
        window.scrollTo(0, 0)
      },[])

    const onNext = (planId) => {
        setPlan(planId);
        setStep(2)
    }

    const [planData, setPlanData] = useState({plans : [], slots : {}});

    useEffect(() => {
        const getPlan = async () => {
            try {
                const {data} = await getPlanData(date);
                setPlanData(data)
                console.log(data)
            } catch (error) {
                console.log(error)  
            }
        }
        getPlan()
    }, [date])

    const [showModel, setShowModel] = useState(false)



 

    return(
        <div>
           
           <div className='hero-inn'>
           <section id="addon-hero">
                <div className="my-container">
                <div className="row city-title">
                <div className="col">
                    <h1 className="heading-primary" style={{color: '#fff'}}>Our Theaters</h1>
                </div>
                <div className="col" style={{display: 'flex', justifyContent: 'end'}}>
                    <div className='bg-light' style={{padding: '8px', borderRadius: '5px'}}>
                    <FormControl>
                               
                               <LocalizationProvider dateAdapter={AdapterDayjs}>
                           <DatePicker minDate={dayjs()} defaultValue={dayjs(date)} onChange={(newdate) => setDate(dayjs(newdate).format('YYYY-MM-DD'))} label="Select Date" />
                           </LocalizationProvider>
                              </FormControl>
                    </div>
                    </div>
            </div>
                </div>
            </section>
            </div>

        <section id="city" className='bg-light'>
        <div className="my-container">
        
            <div className="row city-row">

                {
                    planData.plans.map((plan) => (
                        <div className="city-col" key={plan._id}>
                    <div className="city-box">
                        <div className="city-box-inner">
                            <div className="city-img">
                                <img style={{height: '220px', objectFit: 'cover'}} src={plan.image} />
                            </div>
                            <div className="city-content">
                                    <p className='text' style={{fontSize: '14px', fontWeight: '600'}}>Rs. {plan.price} for {plan.max_Person} person</p>
                                    <h2 className="sub-heading color-primary" style={{marginBottom: '15px'}}>{plan.title}</h2>
                                    <div className='plan-info'>
                                        <div className='plan-info-icon'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person" viewBox="0 0 16 16">
  <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10s-3.516.68-4.168 1.332c-.678.678-.83 1.418-.832 1.664z"/>
</svg>
                                        </div>
                                        <div className='plan-text'>
                                            <p className='text'><strong>Total Person</strong></p>
                                            <p className='text' style={{fontSize: '14px', marginTop:'3px'}}>{plan.total_allowed_person}</p>
                                        </div>
                                    </div>

                                    <div className='plan-info'>
                                        <div className='plan-info-icon'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person" viewBox="0 0 16 16">
  <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10s-3.516.68-4.168 1.332c-.678.678-.83 1.418-.832 1.664z"/>
</svg>
                                        </div>
                                        <div className='plan-text'>
                                            <p className='text'><strong>Increment/Person</strong></p>
                                            <p className='text' style={{fontSize: '14px', marginTop:'3px'}}>Rs. {plan.increment_per_person}/person after {plan.max_Person} person</p>
                                        </div>
                                    </div>

                                    <div className='plan-info'>
                                        <div className='plan-info-icon'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-calendar4" viewBox="0 0 16 16">
  <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M2 2a1 1 0 0 0-1 1v1h14V3a1 1 0 0 0-1-1zm13 3H1v9a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1z"/>
</svg>
                                        </div>
                                        <div className='plan-text'>
                                            <p className='text'><strong>Slot Available</strong></p>
                                            <p className='text' style={{fontSize: '14px', marginTop:'3px', color: planData.slots[plan._id] > 0 ? 'green' : '#00ff00' }}>{planData.slots[plan._id] > 0 ? planData.slots[plan._id] : '0'} Slots available on {dayjs(date).format('D MMMM, YYYY')}</p>
                                        </div>
                                    </div>
                                    
                                    <button type="button" class="btn btn-gradient btn-border" style={{margin: '10px 0'}} onClick={() => onNext(plan._id)}>Book Now</button>
                                    {/* <p className="accent-heading" style={{margin: '0'}}>2 - 10 People</p> */}
                                
                            </div>
                        </div>
                    </div>
                </div>
                    ))
                }

                
            </div>
            <div style={{marginTop: '20px', display: 'flex', justifyContent: 'center'}}>
            <div className="btn-gr-border">
            <button type="button" onClick={() => setShowModel(true)} class="btn"><i class="fa-solid fa-phone"></i><span style={{marginLeft: '8px'}}>Book On Call / Join Waitlist</span></button>
            </div>
                {/* <div className="btn-gr-border" style={{marginLeft: '15px'}}><button onClick={() => setShowModel(true)} type="button" class="btn">Join Waitlist</button></div> */}
            </div>
        </div>
    </section>
    {/* <section id="addon-hero">
                <div className="my-container">
                   <div className="row">
                   <div className="col">
                        <h1 className="hero-heading">SELECT Plan</h1>
                        <span className="accent-heading location-pi"><i class="fa-solid fa-location-dot"></i><span style={{marginLeft: '8px'}}>available 6 Plans</span></span>
                        <ul className="verticle-list">
                            <li><span className="text light">₹2799 for 6 or less people (Rs 400 per extra person)</span></li>
                            <li><span className="text light">Mega 150 inch enhanced 4k Video. Powerful 1000W Dolby atmos sound system (In-wall speakers). Ideal for family and friends.</span></li>
                            <li><span className="text light">Food & Beverages can be ordered at the theatre.</span></li>
                        </ul>
                        <div className="btn-gr-border">
                              <button type="button" onClick={() => setShowModel(true)} class="btn">Book on call</button>
                            </div>
                    </div>
                    <div className="col addon-r-col">
                        <img className="addon-hero-img" src="/assets/img/def.png" />
                    </div>
                   </div>
                </div>
            </section> */}
    {showModel && <BookingRequest setShowModel={setShowModel} />}
    </div>
    )
}

export default Plans;