import {NavLink} from 'react-router-dom'

const About = () => {
    return (
        <div>
             <div className='hero-inn'>
             <section id="addon-hero">
                <div className="my-container">
                   <div className="row" style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                 
                   <h1 className="hero-heading">About US</h1>
                   </div>
                </div>
            </section>
             </div>
            <section id="about" className='bg-light'>
                <div className="my-container">
                    <div class="row">
                        <div className="col abt-col-left">
                            {/* <img style={{borderRadius: '8px'}} className='about-big-img' src={'./assets/img/city.jpg'} alt="" /> */}
                            <img style={{borderRadius: '50%', height: '400px'}}  className='about-big-img' src={'./assets/img/city.jpg'} alt="" />
                            {/* <img style={{borderRadius: '8px'}} className='about-sm-img' src={'./assets/img/city.jpg'} alt="" /> */}
                        </div>
                        <div className="col col-vertical-center abt-col-right">
                            <div>
                            {/* <span className="accent-heading" style={{color:'#386f72', fontWeight:'400'}}>About US</span> */}
                            <h1 className="heading-primary" style={{color:'#fff'}}>Best Private Theaters in  Nellore</h1>
                            <p className="text">We believe that every occasion deserves a special place, and that’s why we’ve created a private theater experience like no other. Our facility features a number of intimate theaters, each equipped with state-of-the-art 4K screens and 5.1 Dolby surround sound, ensuring an immersive and highquality viewing experience.</p>
                             <div style={{display: 'flex'}}>
                             {/* <div className="btn-gr-border">
                              <NavLink to='/about-us'><button type="button" class="btn">Explore More</button></NavLink>
                            </div> */}
                            {/* <NavLink to={'/booknow'}><button style={{marginLeft: '10px'}} type="button" class="btn btn-gradient">Book Now</button></NavLink> */}
                             </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

          

         <section id="about" className='bg-dark'>
                <div className="my-container">
                    <div class="row">
                        <div className="col abt-col-left">

                            <h1 className="heading-primary" style={{color:'#fff'}}>Our Concept</h1>
                            <p className="text">We believe that every occasion deserves a special place, and that’s why we’ve created a private
theater experience. Our facility features a number of intimate theaters, each equipped with 4K
screens and 5.1 Dolby surround sound, ensuring an immersive and high-quality viewing
experience. </p>
                            
                        </div>
                        <div className="col col-vertical-center abt-col-right" style={{justifyContent: 'flex-end'}}>
                            <div>
                            <img style={{borderRadius: '50%', height: '400px'}}  className='about-big-img' src={'./assets/img/city.jpg'} alt="" />
                             </div>
                          
                        </div>
                    </div>
                </div>
            </section>

            <section id="about" className='bg-light'>
                <div className="my-container">
                    <div class="row">
                        <div className="col abt-col-left">
                            {/* <img style={{borderRadius: '8px'}} className='about-big-img' src={'./assets/img/city.jpg'} alt="" /> */}
                            <img style={{borderRadius: '50%', height: '400px'}}  className='about-big-img' src={'./assets/img/city.jpg'} alt="" />
                            {/* <img style={{borderRadius: '8px'}} className='about-sm-img' src={'./assets/img/city.jpg'} alt="" /> */}
                        </div>
                        <div className="col col-vertical-center abt-col-right">
                            <div>
                            {/* <span className="accent-heading" style={{color:'#386f72', fontWeight:'400'}}>About US</span> */}
                            <h1 className="heading-primary" style={{color:'#fff'}}>Delicious Food and Beverages</h1>
                            <p className="text">No celebration is complete without great food and drinks. We offer a delectable menu of food,
snacks, and juices to enhance your experience. Our catering options are tailored to meet your
preferences, ensuring that every bite is as memorable as the occasion itself.</p>
                             <div style={{display: 'flex'}}>
                             {/* <div className="btn-gr-border">
                              <NavLink to='/about-us'><button type="button" class="btn">Explore More</button></NavLink>
                            </div> */}
                            {/* <NavLink to={'/booknow'}><button style={{marginLeft: '10px'}} type="button" class="btn btn-gradient">Book Now</button></NavLink> */}
                             </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section style={{padding: '60px 0'}} className='bg-dark'>
            <div className="my-container"> 
                <div className="row">
                    <div className="col" style={{marginRight: '30px'}}>
                        <h1 className="heading-primary" style={{color: '#fff'}}>Contact US</h1>
                        <p className='text' style={{color: '#fff', opacity: '.8'}}>Ready to celebrate in style? Contact us today to book your private theater and let the celebrations
                        begin!</p>
                        <div className="contact-box" style={{marginTop: '0'}}>
                            <div className="contact-row">
                                <div className="contact-row-l">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone" viewBox="0 0 16 16">
  <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.6 17.6 0 0 0 4.168 6.608 17.6 17.6 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.68.68 0 0 0-.58-.122l-2.19.547a1.75 1.75 0 0 1-1.657-.459L5.482 8.062a1.75 1.75 0 0 1-.46-1.657l.548-2.19a.68.68 0 0 0-.122-.58zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z"/>
</svg>
                                </div>
                                <div className="contact-row-r">
                                    <p className="accent-heading">Phone</p>
                                    <p className="text">+91 98765 43210</p>
                                </div>
                            </div>

                            <div className="contact-row">
                                <div className="contact-row-l">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-envelope" viewBox="0 0 16 16">
  <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z"/>
</svg>
                                </div>
                                <div className="contact-row-r">
                                    <p className="accent-heading">Email</p>
                                    <p className="text">TincyWincyworld@gmail.com</p>
                                </div>
                            </div>

                            <div className="contact-row">
                                <div className="contact-row-l">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-geo-alt" viewBox="0 0 16 16">
  <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A32 32 0 0 1 8 14.58a32 32 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10"/>
  <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4m0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6"/>
</svg>
                                </div>
                                <div className="contact-row-r">
                                    <p className="accent-heading">Address</p>
                                    <p className="text">Nellore, Andhra Pradesh - 524003</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col map-col" style={{marginLeft: '30px'}}>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d61821.107583336176!2d79.93472478064771!3d14.437579373421324!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sDivya%E2%80%99s%20prime%2C%20More%20Super%20Market%20Main%20Road%2C%20Magunta%20layout%2C%20Nellore%2C%20Andhra%20Pradesh%20-%20524003!5e0!3m2!1sen!2sin!4v1724242662890!5m2!1sen!2sin" width="600" height="400" style={{border: 0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
            </div>
        </section>

         
        </div>
    )
}

export default About;