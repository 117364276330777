import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import dayjs from 'dayjs';


import { useState, useEffect } from 'react';
import { createCheckout, verifyCheckOut, applyCoupan, getSelectedPlan } from '../../http';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

import TermsModel from '../TermsModel';

import { validateEmail, validateMobileNumber } from '../../tool/validatoe';

const Review = ({
    step,
    setStep,
    decoration, 
    cake, 
    addon,
    numberOfPerson,
    date,
    amount,
    slot,
    name,
    setName,
    email,
    setEmail,
    mobile,
    setMobile,
    plan,
    eggless,
    nickname_decoration,
    nickname_cake,
    age,
    food
}) => {

    const [totalAmount, setTotalAmount] = useState(amount);
    const [coupan_code, setCoupan] = useState('');
    const [discount, setDiscount] = useState(0);
    const [coupanApplied, setCoupanApplied] = useState(false)

    const [selectedPlan, setSelectedPlan] = useState({features: []});

    const [agreed_with_term, setAgrred] = useState(false);
    const [showModel, setShowModel] = useState(false)

    useEffect(() => {
        const fetchSelectedPlan = async () => {
            try {
                const {data} = await getSelectedPlan(plan)
                setSelectedPlan(data.plan)
                
                
            } catch (error) {
                console.log(error)
            }
        }
        fetchSelectedPlan()
    }, [plan])

    const navigate = useNavigate();

    // useEffect(() => {
        
    //     let addonAmount = addon.reduce((sum, current) => sum + current.price, 0);
    //     let eggPrice = eggless ? cake.eggless_price : cake.price;
    //     setTotalAmount(amount + addonAmount + decoration.price + eggPrice);
    
    // }, [])

    const [selectedSlot, setSelectedSlot] = useState(JSON.parse(slot))

    useEffect(() => {
        window.scrollTo(0, 0)
      },[])

   
    const onNext = async () => {

        if(!name){

            toast.error('Name is required', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light"
            });
            return;

        }
        if(!validateEmail(email)){
            toast.error('Invalid Email', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light"
            });
            return;
        }
        if(!validateMobileNumber(mobile)){

            toast.error('Invalid Mobile Number', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light"
            });
            return;

        }



        try {

            const {data} = await toast.promise(
                createCheckout({
                date,
                plan,
                number_of_person: numberOfPerson,
                slot: selectedSlot,
                decoration,
                cake,
                addons: addon,
                name,
                email,
                mobile,
                amount: totalAmount,
                paid_amount: +totalAmount / 2,
                coupan_code: coupan_code,
                discount: discount,
                nickname_decoration,
                nickname_cake,
                age

            }),
            {
                pending: 'Redirecting to payment page',
                error: 'Redirection failed 🤯'
            }
        )
        window.location = data.data.instrumentResponse.redirectInfo.url
            
        } catch (error) {
            console.log(error)
        }

    }

    const handleApplyCoupan = async () => {

        try {
            const {data} = await toast.promise(
                applyCoupan({coupan_code, total_amount: totalAmount}),
                {
                  pending: 'Please wait..',
                  success: 'Coupan Applied Successfully',
                }

            )
            setDiscount(Math.round(data.discount));
            setCoupanApplied(true)

            console.log(data)
            
        } catch (error) {
            console.log(error)
            toast.error(error.response.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            })
            return
        }

    }

    const modelShow = () => {

        
        if(!name){

            toast.error('Name is required', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light"
            });
            return;

        }
        if(!validateEmail(email)){
            toast.error('Invalid Email', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light"
            });
            return;
        }
        if(!validateMobileNumber(mobile)){

            toast.error('Invalid Mobile Number', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light"
            });
            return;

        }

        setShowModel(true)

    }

   

    return (

        <div>
            {showModel && <TermsModel agreed_with_term={agreed_with_term} setAgrred={setAgrred} setShowModel={setShowModel} onNext={onNext} />}

<div className='hero-inn'>
<section id="addon-hero">
                <div className="my-container">
                   <div className="row" style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                 
                   <h1 className="hero-heading">Checkout</h1>
                   </div>
                </div>
            </section>
            </div>


        <section id="review" className="step-p-section bg-light">
            <div className="my-container">
            <div className="row">
             <div className='col'>
             <div className="widget">
                <h2 class="sub-heading color-primary" style={{marginBottom: '15px'}}>{selectedPlan.title}</h2>
                                <div className='plan-info'>
                                    <div className='plan-info-icon'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-currency-rupee" viewBox="0 0 16 16">
                                            <path d="M4 3.06h2.726c1.22 0 2.12.575 2.325 1.724H4v1.051h5.051C8.855 7.001 8 7.558 6.788 7.558H4v1.317L8.437 14h2.11L6.095 8.884h.855c2.316-.018 3.465-1.476 3.688-3.049H12V4.784h-1.345c-.08-.778-.357-1.335-.793-1.732H12V2H4z" />
                                        </svg>
                                    </div>
                                    <div className='plan-text'>
                                        <p className='text'><strong>Total Amount</strong></p>
                                        <p className='text' style={{ fontSize: '14px', marginTop: '3px' }}>{totalAmount - discount} (inclusive of all taxes)</p>
                                    </div>
                                </div>

                                <div className='plan-info'>
                                    <div className='plan-info-icon'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-calendar4" viewBox="0 0 16 16">
                                            <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M2 2a1 1 0 0 0-1 1v1h14V3a1 1 0 0 0-1-1zm13 3H1v9a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1z" />
                                        </svg>
                                    </div>
                                    <div className='plan-text'>
                                        <p className='text'><strong>Date</strong></p>
                                        <p className='text' style={{ fontSize: '14px', marginTop: '3px' }}>{dayjs(date).format('D MMMM, YYYY')}</p>
                                    </div>
                                </div>

                                <div className='plan-info'>
                                    <div className='plan-info-icon'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clock" viewBox="0 0 16 16">
                                            <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71z" />
                                            <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0" />
                                        </svg>
                                    </div>
                                    <div className='plan-text'>
                                        <p className='text'><strong>Slot</strong></p>
                                        <p className='text' style={{ fontSize: '14px', marginTop: '3px' }}>{selectedSlot.from} - {selectedSlot.to}</p>
                                    </div>
                                </div>

                                <div className='plan-info'>
                                    <div className='plan-info-icon'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person" viewBox="0 0 16 16">
                                            <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10s-3.516.68-4.168 1.332c-.678.678-.83 1.418-.832 1.664z" />
                                        </svg>
                                    </div>
                                    <div className='plan-text'>
                                        <p className='text'><strong>Total Person</strong></p>
                                        <p className='text' style={{ fontSize: '14px', marginTop: '3px' }}>{selectedSlot.from} - {selectedSlot.to}</p>
                                    </div>
                                </div>

                                {
                                    cake._id && (
                                        <div className='plan-info'>
                                        <div className='plan-info-icon'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cake" viewBox="0 0 16 16">
                                                    <path d="m7.994.013-.595.79a.747.747 0 0 0 .101 1.01V4H5a2 2 0 0 0-2 2v3H2a2 2 0 0 0-2 2v4a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-4a2 2 0 0 0-2-2h-1V6a2 2 0 0 0-2-2H8.5V1.806A.747.747 0 0 0 8.592.802zM4 6a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v.414a.9.9 0 0 1-.646-.268 1.914 1.914 0 0 0-2.708 0 .914.914 0 0 1-1.292 0 1.914 1.914 0 0 0-2.708 0A.9.9 0 0 1 4 6.414zm0 1.414c.49 0 .98-.187 1.354-.56a.914.914 0 0 1 1.292 0c.748.747 1.96.747 2.708 0a.914.914 0 0 1 1.292 0c.374.373.864.56 1.354.56V9H4zM1 11a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v.793l-.354.354a.914.914 0 0 1-1.293 0 1.914 1.914 0 0 0-2.707 0 .914.914 0 0 1-1.292 0 1.914 1.914 0 0 0-2.708 0 .914.914 0 0 1-1.292 0 1.914 1.914 0 0 0-2.708 0 .914.914 0 0 1-1.292 0L1 11.793zm11.646 1.854a1.915 1.915 0 0 0 2.354.279V15H1v-1.867c.737.452 1.715.36 2.354-.28a.914.914 0 0 1 1.292 0c.748.748 1.96.748 2.708 0a.914.914 0 0 1 1.292 0c.748.748 1.96.748 2.707 0a.914.914 0 0 1 1.293 0Z" />
                                                </svg>
                                        </div>
                                        <div className='plan-text'>
                                            <p className='text'><strong>Cake</strong></p>
                                            <p className='text' style={{ fontSize: '14px', marginTop: '3px' }}>{cake.title}{nickname_cake ? ` , Name on Cake - ${nickname_cake}` : ""}</p>
                                        </div>
                                    </div>
                                    )
                                }

{
                                    addon.some((ad) => ad.title == "Age Number") && (
                                        <div className='plan-info'>
                                        <div className='plan-info-icon'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cake" viewBox="0 0 16 16">
                                                    <path d="m7.994.013-.595.79a.747.747 0 0 0 .101 1.01V4H5a2 2 0 0 0-2 2v3H2a2 2 0 0 0-2 2v4a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-4a2 2 0 0 0-2-2h-1V6a2 2 0 0 0-2-2H8.5V1.806A.747.747 0 0 0 8.592.802zM4 6a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v.414a.9.9 0 0 1-.646-.268 1.914 1.914 0 0 0-2.708 0 .914.914 0 0 1-1.292 0 1.914 1.914 0 0 0-2.708 0A.9.9 0 0 1 4 6.414zm0 1.414c.49 0 .98-.187 1.354-.56a.914.914 0 0 1 1.292 0c.748.747 1.96.747 2.708 0a.914.914 0 0 1 1.292 0c.374.373.864.56 1.354.56V9H4zM1 11a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v.793l-.354.354a.914.914 0 0 1-1.293 0 1.914 1.914 0 0 0-2.707 0 .914.914 0 0 1-1.292 0 1.914 1.914 0 0 0-2.708 0 .914.914 0 0 1-1.292 0 1.914 1.914 0 0 0-2.708 0 .914.914 0 0 1-1.292 0L1 11.793zm11.646 1.854a1.915 1.915 0 0 0 2.354.279V15H1v-1.867c.737.452 1.715.36 2.354-.28a.914.914 0 0 1 1.292 0c.748.748 1.96.748 2.708 0a.914.914 0 0 1 1.292 0c.748.748 1.96.748 2.707 0a.914.914 0 0 1 1.293 0Z" />
                                                </svg>
                                        </div>
                                        <div className='plan-text'>
                                            <p className='text'><strong>Person age</strong></p>
                                            <p className='text' style={{ fontSize: '14px', marginTop: '3px' }}>{age} Years.</p>
                                        </div>
                                    </div>
                                    )
                                }

                                {
                                    decoration._id && (
                                        <div className='plan-info'>
                                        <div className='plan-info-icon'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-balloon-heart" viewBox="0 0 16 16">
                                                    <path fill-rule="evenodd" d="m8 2.42-.717-.737c-1.13-1.161-3.243-.777-4.01.72-.35.685-.451 1.707.236 3.062C4.16 6.753 5.52 8.32 8 10.042c2.479-1.723 3.839-3.29 4.491-4.577.687-1.355.587-2.377.236-3.061-.767-1.498-2.88-1.882-4.01-.721zm-.49 8.5c-10.78-7.44-3-13.155.359-10.063q.068.062.132.129.065-.067.132-.129c3.36-3.092 11.137 2.624.357 10.063l.235.468a.25.25 0 1 1-.448.224l-.008-.017c.008.11.02.202.037.29.054.27.161.488.419 1.003.288.578.235 1.15.076 1.629-.157.469-.422.867-.588 1.115l-.004.007a.25.25 0 1 1-.416-.278c.168-.252.4-.6.533-1.003.133-.396.163-.824-.049-1.246l-.013-.028c-.24-.48-.38-.758-.448-1.102a3 3 0 0 1-.052-.45l-.04.08a.25.25 0 1 1-.447-.224l.235-.468ZM6.013 2.06c-.649-.18-1.483.083-1.85.798-.131.258-.245.689-.08 1.335.063.244.414.198.487-.043.21-.697.627-1.447 1.359-1.692.217-.073.304-.337.084-.398" />
                                                </svg>
                                        </div>
                                        <div className='plan-text'>
                                            <p className='text'><strong>Decoration</strong></p>
                                            <p className='text' style={{ fontSize: '14px', marginTop: '3px' }}>{decoration.title}{nickname_decoration ? ` , Name on Decor. - ${nickname_decoration}` : ""}</p>
                                        </div>
                                    </div>
                                    )
                                }
                                <div className='plan-info'>
                                        <div className='plan-info-icon'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-box-seam" viewBox="0 0 16 16">
  <path d="M8.186 1.113a.5.5 0 0 0-.372 0L1.846 3.5l2.404.961L10.404 2zm3.564 1.426L5.596 5 8 5.961 14.154 3.5zm3.25 1.7-6.5 2.6v7.922l6.5-2.6V4.24zM7.5 14.762V6.838L1 4.239v7.923zM7.443.184a1.5 1.5 0 0 1 1.114 0l7.129 2.852A.5.5 0 0 1 16 3.5v8.662a1 1 0 0 1-.629.928l-7.185 2.874a.5.5 0 0 1-.372 0L.63 13.09a1 1 0 0 1-.63-.928V3.5a.5.5 0 0 1 .314-.464z"/>
</svg>
                                        </div>
                                        <div className='plan-text'>
                                            <p className='text'><strong>Opt. for food</strong></p>
                                            <p className='text' style={{ fontSize: '14px', marginTop: '3px' }}>{food ? 'Yes' : 'No'}</p>
                                        </div>
                                    </div>
                    {addon.length > 0 && (<div className="addon-review">
                        <p className="accent-heading" style={{marginBottom: '10px'}}>Add-on</p>
                        <div className="addon-review-inner">
                           {
                            addon.map(add =>  (<div className="addont-review-tag ">
                                <img src={add.image} alt='addon' />
                                <div>
                                   <p className='text'><strong>{add.title}</strong></p>
                                   <p>₹:{add.price}</p>
                                </div>
                            </div>))
                           }
                        </div>
                    </div>)}
                </div>
             </div>

             <div className='col'>
             <div className="widget">

<h2 class="sub-heading color-primary" style={{marginBottom: '15px'}}>Enter Your Detail To Proceed</h2>

<FormControl fullWidth style={{margin: '10px 0'}}>
    <TextField defaultValue={name} onChange={(e) => setName(e.target.value)} sx={{color: 'red'}} label="Name" variant="outlined" />
</FormControl>

<FormControl fullWidth style={{margin: '10px 0'}}>
    <TextField defaultValue={mobile} onChange={(e) => setMobile(e.target.value)} label="Mobile" variant="outlined" />
</FormControl>

<FormControl fullWidth>
    <TextField label="Email" type='email' defaultValue={email} onChange={(e) => setEmail(e.target.value)} variant="outlined" />
</FormControl>

</div>
<div className='widget' style={{border: '2px solid #de80981c'}}>
  <h2 class="sub-heading color-primary" style={{marginBottom: '15px'}}>Do you have a coupon?</h2>
 <div style={{display: 'flex', alignItems: 'center'}}>
 <FormControl fullWidth style={{margin: '10px 20px 10px 0'}}>
    <TextField disabled={coupanApplied ? true : false} defaultValue={coupan_code} onChange={(e) => setCoupan(e.target.value)} sx={{color: 'red'}} label="Coupon Code" variant="outlined" />
 </FormControl>
 <div className="btn-gr-border">
              <button disabled={coupanApplied ? true : false} type="button" onClick={handleApplyCoupan} class="btn btn-full">Apply</button>
            </div>
 </div>
</div>



<div className="row btn-row" style={{justifyContent: 'space-between'}}>
<div className="btn-gr-border">
              <button type="button" onClick={() => setStep(step => step - 1)} class="btn">Go Back</button>
            </div>
            <button onClick={modelShow} type="button" class="btn btn-gradient btn-border">Proceed to Pay</button>
</div>
<p className='text' style={{marginTop: '20px', color: '#f95e5e'}}><i>We collect an advance amount to book the slot. Remaining bill will be collected at the time of check-out</i></p>
             </div>
            
            </div>
            </div>
        </section>
        </div>
    )
}

export default Review;