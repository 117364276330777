import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { useState, useEffect } from 'react';
import { getCakes } from '../../http';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';

const Cake = ({step, setStep, cake, setCake, eggless, setEggless, amount,   nickname_cake, setNickNameCake,}) => {

   const [cakes, setCakes] = useState([]);
   const [reload, setReload] = useState(false);

   useEffect(() => {
      window.scrollTo(0, 0)
    },[])
   
   useEffect(() => {
      const fetchCakes = async () => {
         try {
            const {data} = await getCakes();
            setCakes(data.cakes)
         } catch (error) {
            console.log(error)
         }
      }
      fetchCakes()
   }, [])

   const onNext = () => {
      setStep(step => step + 1);
   }

   const handleCake = (cak) => {
      
      let pre = {...cake};
  
      if (cake.price == 0) {
          setCake(cak);
          return;
      }
  
      // Check if the item is already in the array
      // const exists = pre.some(ad => ad._id === add._id);
  
      if (cake._id == cak._id) {
          // If the item exists, remove it
          setCake({price: 0, eggless_price: 0})
          
      } else {
          // If the item does not exist, add it
         setCake(cak)
         
      }


  };
  

    return (

      <div>

         <div className='hero-inn'>

<section id="addon-hero">
                <div className="my-container">
                   <div className="row" style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                 
                   <h1 className="hero-heading">Cake</h1>
                   </div>
                </div>
            </section>
            </div>

        <section id="cake" className="step-p-section bg-light">
           <div className="my-container">
           <div className="price-row">
            <h1 className="heading-primary" style={{color:'#fff'}}>Select Cake</h1>
            <p className="accent-heading" style={{marginBottom: '0'}}>Total: RS. {amount}</p>
            </div>
             <center>

             <FormControlLabel control={<Switch checked={eggless} onChange={(e) => setEggless(e.target.checked)} />} label="Eggless" />
             </center>
             <div className="addon-box">
                         
                         {
                           cakes.map((cak) => (
                              <div style={{display: (eggless && !Boolean(cak.eggless_price)) ? 'none' : 'block'}} className={`addon-card ${cake._id == cak._id ? 'selectrd-card' : ''}`} key={cak._id}>
                              <div className="addon-card-inner" onClick={() => handleCake(cak)}>
                                 
                                 <img src={cak.image} />
                                 <p className="accent-heading" style={{marginTop:'20px', marginBottom:'10px'}}>{cak.title}</p>
                                 <p className="text"><strong>₹: {eggless ? cak.eggless_price : cak.price}</strong></p>
                                 
                              </div>
                            </div>
                           ))
                         }
                             
                            </div>
                            <p className='text' style={{marginBottom: '20px', color: '#f95e5e'}}><i><strong>Note: </strong> Above images are for demonstration purpose only, Actual cakes may look different</i></p>
                            <div style={{display: 'flex', justifyContent: 'center'}}>
                            {cake._id ? (<div style={{maxWidth: '400px'}}>
                             <FormControl fullWidth style={{margin: '10px 0'}}>
    <TextField defaultValue={nickname_cake} onChange={(e) => setNickNameCake(e.target.value)} sx={{color: 'red'}} label="Special Person Name for cake" variant="outlined" />
</FormControl>
<p className="text" style={{fontSize: '13px', opacity: '.6'}}>if more than one person, separate name with comma</p>
                             </div>) : null}
                            </div>
            <div className="row btn-row" style={{justifyContent: 'space-between'}}>
            <div className="btn-gr-border">
                              <button onClick={() => setStep(step => step - 1)} type="button" class="btn">Go Back</button>
                            </div>
                            <button onClick={onNext} type="button" class="btn btn-gradient btn-border">Next</button>
            </div>
           </div>
        </section>
        </div>
    )
}

export default Cake;