

const Terms = () => {
    return (
       <div>
        <div className="hero-inn">
            <section id="addon-hero">
                <div className="my-container">
                   <div className="row" style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                 
                   <h1 className="hero-heading">Terms & Conditions</h1>
                   </div>
                </div>
            </section>
            </div>
  
           <section className="bg-light">
            <div className="my-container" style={{padding: '60px 0'}}>
            <p data-list='1' className="text term-list">
                 We do not provide any Movies / OTT accounts. we will do the setup using your OTT acount / downloaded content.
                </p>
                <p data-list='2' className="text term-list">
                  Smoking/Drinking is not allowed inside the theater.
                </p>
                <p data-list='3' className="text term-list">
                 Any Damage caused to theater, including decorative material like baloon, lights etc will have to be reimbursed.
                </p>
                <p data-list='4' className="text term-list">
                 Guests are requested to maintain CLEANINESS inside the theater.
                </p>
                <p data-list='5' className="text term-list">
                 Party Poppers/Snow Sprayes/Cold Fire, and any other similar items are strictily prohibited inside the theater.
                </p>
                <p data-list='6' className="text term-list">
                 Carrying ADHAR CARD is mendatory. it will be scanned during entry.
                </p>
                <p data-list='7' className="text term-list">
                 Coupled under 18 year of age are not allowe to book the theater.
                </p>
                <p data-list='8' className="text term-list">
                 Pets are strictily not allowed inside the theater.
                </p>
                <p data-list='9' className="text term-list">
                 We collect an advance amount of RS. 750 plus convenience fee to book the slot.
                </p>
            </div>
           </section>

       </div>
    )
}


export default Terms;