
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import dayjs from 'dayjs';
import { useState, useEffect } from 'react';
import { getSelectedPlan, getSlotsData, getCityByID } from '../../http';

import { ToastContainer, toast } from 'react-toastify';
import BookingRequest from '../BookingRequest/BookingRequest';

const Slot = ({
    step,
    setStep,
    date,
    setDate,
    plan,
    numberOfPerson,
    setNumberOfPerson,
    slot, 
    setSlot,
    amount,
    setAmount,
    theaterAmount, 
    setTheaterAmount
}) => {

    const [selectedPlan, setSelectedPlan] = useState({features: []})
    const [calculatedPrice, setCalculatedPrice] = useState();
    const [slots, setSlots] = useState([])
  

    useEffect(() => {
        const fetchSelectedPlan = async () => {
            try {
                const {data} = await getSelectedPlan(plan)
                setSelectedPlan(data.plan)
                setCalculatedPrice(data.plan.price);
                
            } catch (error) {
                console.log(error)
            }
        }
        fetchSelectedPlan()
    }, [plan])

    

    useEffect(() => {
        const fetchSlot = async () => {
            try {
                const {data} = await getSlotsData(date, plan)
                console.log(data)
                setSlots(data.slots)
            } catch (error) {
                console.log(error)
            }
        }
        fetchSlot();
    }, [date, plan])

    useEffect(() => {

        if(numberOfPerson > selectedPlan.max_Person){
            let priceToInc = (numberOfPerson - selectedPlan.max_Person) * selectedPlan.increment_per_person;
            setCalculatedPrice(priceToInc + selectedPlan.price)
            setTheaterAmount(priceToInc + selectedPlan.price)
        }else{
            setCalculatedPrice(selectedPlan.price)
            setTheaterAmount(selectedPlan.price)
        }
        
    }, [numberOfPerson, selectedPlan])

    const onNext = () => {

        if(!date){

            toast.error('Date is required', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light"
            });
            return;

        }
        if(!numberOfPerson){
            toast.error('Number of person is required', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light"
            });
            return
        }
        if(!slot){
            toast.error('Slot is required', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light"
            });
            return;
        }
        setAmount(calculatedPrice);
        
        setStep(step => step + 1)
    }

    useEffect(() => {
        window.scrollTo(0, 0)
      },[])
      const [showModel, setShowModel] = useState(false)


    return (
        <div>

<div className='hero-inn'>
<section id="addon-hero">
                <div className="my-container">
                   <div className="row" style={{paddingTop: '50px'}}>
                   <div className="col" style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', padding: '0'}}>
                        <h1 className="hero-heading" style={{marginTop: '0'}}>{selectedPlan.title}</h1>
                        {/* <span className="accent-heading location-pi"><i class="fa-solid fa-location-dot"></i><span style={{marginLeft: '8px'}}>Hyderabad</span></span> */}
                        <ul className="verticle-list">
                            {
                                selectedPlan.features.map((fea) =>   <li>

                                    <span className="text light">
                                    <svg style={{marginRight: '5px'}} xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-stars" viewBox="0 0 16 16">
  <path d="M7.657 6.247c.11-.33.576-.33.686 0l.645 1.937a2.89 2.89 0 0 0 1.829 1.828l1.936.645c.33.11.33.576 0 .686l-1.937.645a2.89 2.89 0 0 0-1.828 1.829l-.645 1.936a.361.361 0 0 1-.686 0l-.645-1.937a2.89 2.89 0 0 0-1.828-1.828l-1.937-.645a.361.361 0 0 1 0-.686l1.937-.645a2.89 2.89 0 0 0 1.828-1.828zM3.794 1.148a.217.217 0 0 1 .412 0l.387 1.162c.173.518.579.924 1.097 1.097l1.162.387a.217.217 0 0 1 0 .412l-1.162.387A1.73 1.73 0 0 0 4.593 5.69l-.387 1.162a.217.217 0 0 1-.412 0L3.407 5.69A1.73 1.73 0 0 0 2.31 4.593l-1.162-.387a.217.217 0 0 1 0-.412l1.162-.387A1.73 1.73 0 0 0 3.407 2.31zM10.863.099a.145.145 0 0 1 .274 0l.258.774c.115.346.386.617.732.732l.774.258a.145.145 0 0 1 0 .274l-.774.258a1.16 1.16 0 0 0-.732.732l-.258.774a.145.145 0 0 1-.274 0l-.258-.774a1.16 1.16 0 0 0-.732-.732L9.1 2.137a.145.145 0 0 1 0-.274l.774-.258c.346-.115.617-.386.732-.732z"/>
</svg>
                                        <span>{fea.text}</span>
                                    </span>
                                </li>)
                            }
                        </ul>
                        <div className="btn-gr-border call-desk">
                              <button type="button" onClick={() => setShowModel(true)} class="btn">Book On Call</button>
                            </div>
                            <div className="btn-gr-border call-mobile">
                              <button type="button" class="btn"><a href='tel:+919876543210' style={{color: '#001328'}}>Book On Call</a></button>
                            </div>
                    </div>
                    <div className="col addon-r-col">
                        <img className="addon-hero-img" src={selectedPlan.image} />
                    </div>
                   </div>
                </div>
            </section>
            </div>
            
        <section id="slot" className="step-p-section bg-light">
            <div className="my-container">
            <div className="price-row" style={{marginBottom:'40px'}}>
            <h1 className="heading-primary" style={{color:'#fff'}}>Select Slots</h1>
            <p className="accent-heading" style={{marginBottom: '0'}}>Total: RS. {calculatedPrice}</p>
            </div>
            <div className="row" style={{flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%'}}>
                        <div className='col'>
                        <div className="widget">
                               <FormControl fullWidth>
                               
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker minDate={(dayjs())} defaultValue={dayjs(date)} onChange={(newdate) => setDate(dayjs(newdate).format('YYYY-MM-DD'))} label="Select Date" />
                            </LocalizationProvider>
                               </FormControl>

                              <div style={{margin: '20px 0'}}>
                              <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Number of Person</InputLabel>
                                    <Select
                                        value={numberOfPerson}
                                        onChange={(e) => setNumberOfPerson(e.target.value)}
                                        label="Number of Person"

                                    >
                                        {
                                            Array.from({length: selectedPlan.total_allowed_person}, (_, index) => <MenuItem value={index + 1}>{index + 1}</MenuItem>)
                                        }
                                    </Select>
                                </FormControl>
                              </div>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Slots</InputLabel>
                                    <Select
                                        value={slot}
                                        label="Slots"
                                        onChange={(e) => setSlot(e.target.value)}

                                    >
                                        {slots.map((slot) =>  <MenuItem className={slot.expired ? 'disble-slot' : ''} disabled={slot.expired} value={JSON.stringify(slot)}>{`${slot.from} - ${slot.to}`}</MenuItem>)}
                                    </Select>
                                </FormControl>
                        </div>

                        </div>
                   <div className='col'>
                   {/* <div className='widget widget-2'>
                            <h1 className='hero-heading' style={{color: '#e0839b'}}>₹: {calculatedPrice}</h1>
                            <p className='text' style={{color: '#e0839b', marginTop: '30px'}}>Inlusive of all taxes.</p>
                        </div> */}

                        <div className="row btn-row" style={{justifyContent: 'space-between'}}>
            <div className="btn-gr-border">
                              <button type="button" class="btn" onClick={() => setStep(step => step - 1)}>Go Back</button>
                            </div>
                            <button type="button" class="btn btn-gradient btn-border" onClick={onNext}>Next</button>
            </div>
                   </div>
                    </div>
                    
            </div>
        </section>
        
        {showModel && <BookingRequest setShowModel={setShowModel} />}
        </div>
    )
}

export default Slot;