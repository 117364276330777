import CategoryCard from "../components/CategoryCard";
import Typewriter from "typewriter-effect";
import { useRef, useEffect, useState, act } from "react";
import Slider from "react-slick";
import AddonCard from "../components/AddonCard";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { NavLink, useNavigate } from "react-router-dom";
import { getAddons, getAppSeting } from "../http";
import BookingRequest from "./BookingRequest/BookingRequest";



const Home = () => {
 
    const [showModel, setShowModel] = useState(false)

    const typingRef = useRef(null)

    const [addons, setAddons] = useState([]);
    const [app, setApp] = useState({})

    const [texts, setTexts] = useState(['Farewell', 'Bride to be', 'Anniversary', 'Love proposal', 'Birthdays', 'Baby shower', 'Romantic dates', 'Wedding proposal', 'Groom to be', 'Entertainment']);
    const [activeTexat, setActiveText] = useState(0);

    const navigate = useNavigate();

    useEffect(() => {
        const myInterval = setInterval(() => {
            if(activeTexat < texts.length - 1){
                let n = activeTexat + 1;
                setActiveText(n)
            }else{
                setActiveText(0)
            }
            console.log(activeTexat)
        }, 2000)
        return () => clearInterval(myInterval)
    }, [activeTexat])

    useEffect(() => {
        const fetchAddons = async () => {
          try {
    
            const {data} = await getAddons();
            const res = await getAppSeting();
            setApp(res.data.setting)
            setAddons(data.addons)
            
          } catch (error) {
            console.log(error)
          }
        }
        fetchAddons()
      }, [])


    var settings = {
        dots: false,
        infinite: true,
        speed: 3000,
        auto: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed:2000,
        spacing: 40,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1
                }
            },
            {
                breakpoint: 1023,
                settings: {
                    slidesToShow: 2
                }
            }
        ]
    };

  

  


    return (
        <div>


      {/* Hero Section */}
         <section id="hero-section" style={{backgroundImage: `url(${app.banner_image})`, paddingTop: '150px'}}>
           
           <div className="my-container">
                <div className="row">
                    <div className="hero-content">
                        <div className="content" >
                            <div className="hero-h-box">
                              <h1 style={{textAlign: 'center'}} className="hero-heading">Create unforgettable memories of</h1> 
                           
                                
                                    {/* <Typewriter options={{loop: true}} 
                                    onInit={(typewriter) => {
                                        typewriter
                                        .typeString("Farewell")
                                        .pauseFor(1000)
                                        .deleteAll()
                                        .typeString('Birthday')
                                        .pauseFor(1000)
                                        .deleteAll()
                                        .typeString('Anniversary')
                                        .pauseFor(1000)
                                        .deleteAll()
                                        .start()
                                    }}
                                    /> */}
                                    <div className="anim-text-box">
                                    {
                                        texts.map((text, i) => <h1 style={{textAlign: 'center'}} className={`anim-text gradient-text hero-heading ${activeTexat == i ? 'active-text' : ''}`}>{text}</h1>)
                                    }
                                    </div>
                            
                               <h1 style={{textAlign: 'center'}} className="hero-heading">at our venue.</h1>
                            </div>
                           
                            <div style={{display: 'flex', width: '100%', justifyContent: 'center', marginTop: '25px'}}>
                            <NavLink to={'/booknow'}><button type="button" class="btn btn-gradient btn-hover-2">Book Now</button></NavLink>
                            <button onClick={() => setShowModel(true)} type="button" class="btn btn-gradient btn-hover-2 call-desk" style={{marginLeft: '10px'}}>Book on Call</button>
                            <button type="button" class="btn btn-gradient btn-hover-2 call-mobile" style={{marginLeft: '10px'}}><a style={{color: '#fff'}} href="tel:+919876543210">Book on Call</a></button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>

        <section className="bg-dark">
        <div className="my-container" style={{padding: '60px 0'}}>
                <h1 className="heading-primary" style={{textAlign: 'center', color: '#fff', margin: '0 0 20px 0'}}>Elevate your celebrations with a unique experience at our private theatre.</h1>
                <div className="grid-home grid-occ">

                    <NavLink to={'/booknow'}>
                    <div className="addon-card addon-card-full bg-light">
                        <div className="addon-card-inner">
                            <center>
                                <img src='/assets/img/ent.jpg' />
                                <p className="accent-heading" style={{marginTop:'20px', marginBottom:'10px'}}>Farewell</p>
                            </center>
                        </div>
                    </div>
                    </NavLink>

                    <NavLink to={'/booknow'}>
                    <div className="addon-card addon-card-full bg-light">
                        <div className="addon-card-inner">
                            <center>
                                <img src='/assets/img/brd.jpg' />
                                <p className="accent-heading" style={{marginTop:'20px', marginBottom:'10px'}}>Bride to be</p>
                            </center>
                        </div>
                    </div>
                    </NavLink>

                    <NavLink to={'/booknow'}>
                    <div className="addon-card addon-card-full bg-light">
                        <div className="addon-card-inner">
                            <center>
                                <img src='/assets/img/anv.jpg' />
                                <p className="accent-heading" style={{marginTop:'20px', marginBottom:'10px'}}>Anniversaries</p>
                            </center>
                        </div>
                    </div>
                    </NavLink>
 
                    <NavLink to={'/booknow'}>
                    <div className="addon-card addon-card-full bg-light">
                        <div className="addon-card-inner">
                            <center>
                                <img src='/assets/img/lov.jpg' />
                                <p className="accent-heading" style={{marginTop:'20px', marginBottom:'10px'}}>Love proposal</p>
                            </center>
                        </div>
                    </div>
                    </NavLink>


                    <NavLink to={'/booknow'}>
                    <div className="addon-card addon-card-full bg-light">
                        <div className="addon-card-inner">
                            <center>
                                <img src='/assets/img/bd.jpg' />
                                <p className="accent-heading" style={{marginTop:'20px', marginBottom:'10px'}}>Birthdays</p>
                            </center>
                        </div>
                    </div>
                    </NavLink>


                    <NavLink to={'/booknow'}>
                    <div className="addon-card addon-card-full bg-light">
                        <div className="addon-card-inner">
                            <center>
                                <img src='/assets/img/mom.jpg' />
                                <p className="accent-heading" style={{marginTop:'20px', marginBottom:'10px'}}>Baby shower</p>
                            </center>
                        </div>
                    </div>
                    </NavLink>

                    <NavLink to={'/booknow'}>
                    <div className="addon-card addon-card-full bg-light">
                        <div className="addon-card-inner">
                            <center>
                                <img src='/assets/img/ddd.jpg' />
                                <p className="accent-heading" style={{marginTop:'20px', marginBottom:'10px'}}>Romantic dates</p>
                            </center>
                        </div>
                    </div>
                    </NavLink>

                    <NavLink to={'/booknow'}>
                    <div className="addon-card addon-card-full bg-light">
                        <div className="addon-card-inner">
                            <center>
                                <img src='/assets/img/pro.jpg' />
                                <p className="accent-heading" style={{marginTop:'20px', marginBottom:'10px'}}>Wedding proposal</p>
                            </center>
                        </div>
                    </div>
                    </NavLink>

                    <NavLink to={'/booknow'}>
                    <div className="addon-card addon-card-full bg-light">
                        <div className="addon-card-inner">
                            <center>
                                <img src='/assets/img/grm.jpg' />
                                <p className="accent-heading" style={{marginTop:'20px', marginBottom:'10px'}}>Groom to be</p>
                            </center>
                        </div>
                    </div>
                    </NavLink>

                    <NavLink to={'/booknow'}>
                    <div className="addon-card addon-card-full bg-light">
                        <div className="addon-card-inner">
                            <center>
                                <img src='/assets/img/entt.jpg' />
                                <p className="accent-heading" style={{marginTop:'20px', marginBottom:'10px'}}>Entertainment</p>
                            </center>
                        </div>
                    </div>
                    </NavLink>

                </div>
            </div>
        </section>

        <section className="bg-light">
        <div className="my-container" style={{padding: '60px 0'}}>

                <h1 className="heading-primary" style={{textAlign: 'center', margin: '0px 0 20px 0', color: '#fff'}}>What we offer</h1>
                <div className="grid-home">

                    <div className="addon-card addon-card-full addon-dark-bg">
                        <div className="addon-card-inner">
                            <center>
                                <img src='/assets/img/decor.jpg' />
                                <p className="accent-heading" style={{marginTop:'20px', marginBottom:'10px'}}>Decoration</p>
                            </center>
                        </div>
                    </div>

                    <div className="addon-card addon-card-full addon-dark-bg">
                        <div className="addon-card-inner">
                            <center>
                                <img src='/assets/img/rose.jpg' />
                                <p className="accent-heading" style={{marginTop:'20px', marginBottom:'10px'}}>Floral</p>
                            </center>
                        </div>
                    </div>

                    <div className="addon-card addon-card-full addon-dark-bg">
                        <div className="addon-card-inner">
                            <center>
                                <img src='/assets/img/photography.jpg' />
                                <p className="accent-heading" style={{marginTop:'20px', marginBottom:'10px'}}>Photography</p>
                            </center>
                        </div>
                    </div>

                    <div className="addon-card addon-card-full addon-dark-bg">
                        <div className="addon-card-inner">
                            <center>
                                <img src='/assets/img/food.jpg' />
                                <p className="accent-heading" style={{marginTop:'20px', marginBottom:'10px'}}>Food</p>
                            </center>
                        </div>
                    </div>

                    <div className="addon-card addon-card-full addon-dark-bg">
                        <div className="addon-card-inner">
                            <center>
                                <img src='/assets/img/cake.jpg' />
                                <p className="accent-heading" style={{marginTop:'20px', marginBottom:'10px'}}>Cake</p>
                            </center>
                        </div>
                    </div>

                    <div className="addon-card addon-card-full addon-dark-bg">
                        <div className="addon-card-inner">
                            <center>
                                <img src='/assets/img/city.jpg' />
                                <p className="accent-heading" style={{marginTop:'20px', marginBottom:'10px'}}>Theater</p>
                            </center>
                        </div>
                    </div>

                </div>
            </div>
        </section>


        {/* section two */}


       


        {/* Section  Three */}
            <section id="cat" className="bg-dark">
                <div className="my-container">
                    <div class="row" style={{justifyContent:'center'}}>
                       
                            <div style={{maxWidth:'800px'}}>
                            <h1 className="heading-primary" style={{color:'#fff', textAlign: 'center'}}>Our <span style={{color:'#fff'}}>Concept</span> </h1>
                            <p className="text" style={{textAlign: 'center', marginBottom: '20px', color: '#fff'}}>We believe that every occasion deserves a special place, and that’s why we’ve created a private
theater experience like no other. Our facility features a number of intimate theaters, each equipped
with state-of-the-art 4K screens and 5.1 Dolby surround sound, ensuring an immersive and highquality viewing experience. </p>
                             <div style={{display: 'flex', justifyContent: 'center'}}>
                             <div className="btn-gr-border">
                              <NavLink to='/about-us'><button type="button" class="btn">Explore More</button></NavLink>
                            </div>
                            <NavLink to={'/booknow'}><button style={{marginLeft: '10px'}} type="button" class="btn btn-gradient">Book Now</button></NavLink>
                             </div>
                            
                        </div>
                    </div>
                </div>
            </section>
         



        
        <section style={{padding: '60px 0'}} className="bg-light">
            <div className="my-container">
                <div className="row">
                    <div className="col">
                        <h1 className="heading-primary" style={{color: '#fff'}}>Contact US</h1>
                        <div className="contact-box">
                            <div className="contact-row">
                                <div className="contact-row-l">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone" viewBox="0 0 16 16">
  <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.6 17.6 0 0 0 4.168 6.608 17.6 17.6 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.68.68 0 0 0-.58-.122l-2.19.547a1.75 1.75 0 0 1-1.657-.459L5.482 8.062a1.75 1.75 0 0 1-.46-1.657l.548-2.19a.68.68 0 0 0-.122-.58zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z"/>
</svg>
                                </div>
                                <div className="contact-row-r">
                                    <p className="accent-heading">Phone</p>
                                    <p className="text">+91 98765 43210</p>
                                </div>
                            </div>

                            <div className="contact-row">
                                <div className="contact-row-l">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-envelope" viewBox="0 0 16 16">
  <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z"/>
</svg>
                                </div>
                                <div className="contact-row-r">
                                    <p className="accent-heading">Email</p>
                                    <p className="text">TincyWincyworld@gmail.com</p>
                                </div>
                            </div>

                            <div className="contact-row">
                                <div className="contact-row-l">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-geo-alt" viewBox="0 0 16 16">
  <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A32 32 0 0 1 8 14.58a32 32 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10"/>
  <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4m0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6"/>
</svg>
                                </div>
                                <div className="contact-row-r">
                                    <p className="accent-heading">Address</p>
                                    <p className="text">Nellore, Andhra Pradesh - 524003</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col map-col">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d61821.107583336176!2d79.93472478064771!3d14.437579373421324!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sDivya%E2%80%99s%20prime%2C%20More%20Super%20Market%20Main%20Road%2C%20Magunta%20layout%2C%20Nellore%2C%20Andhra%20Pradesh%20-%20524003!5e0!3m2!1sen!2sin!4v1724242662890!5m2!1sen!2sin" width="600" height="400" style={{border: 0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
            </div>
        </section>
            {showModel && <BookingRequest setShowModel={setShowModel} />}
        </div>
    )
}

export default Home;